// `level` comes from arg passed to `reducer` when invoked
const reducer = (level) => (acc, curr) => {
    const currWithLevel = Object.assign(Object.assign({}, curr), { level });
    let items = [];
    if (curr && curr.items) {
        items = curr.items.reduce(reducer(level + 1), []);
    }
    return [...acc, currWithLevel, ...items];
};
export const getHeadings = (tableOfContents) => {
    // 'top' level headings start at level 0
    return tableOfContents.items.reduce(reducer(0), []);
};