import styled from 'styled-components';


export const Container = styled.nav`
    ul {
        display: grid;
        grid-template-columns: 1fr;
        font-size: 1.1rem;
        list-style: none;
    }
    position: sticky;
    top: 90px;
    width: 100%;
`;

export const TableOfContentsTitle =styled.span`
@media screen and (min-width: 1024px){
    text-align:center;
    min-width: 100%;
}
    color: inherit;
    -webkit-appearance: none;
    text-rendering: optimizeLegibility;
    font-family: "Anton", "Impact", Impact;
    font-weight: 400;
    line-height: 120%;
    font-size: 2rem;
    text-transform: uppercase;
    cursor: pointer;
`

export const ShowHideSpan = styled.span`
font-size: 1rem; 
cursor: pointer;
`

export const CircledNumber = styled.div`
transition: all 0.4s linear;
@media screen and (min-width: 1024px){
    box-sizing: border-box;
    margin: 0px 0.5rem 0px 0px;
    min-width: 0px;
    font-family: Inter, sans-serif;
    line-height: 1.33;
    font-weight: 600;
    text-decoration: none;
    font-size: 0.875rem;
    color: var(--theme-ui-colors-alpha,#667eea);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    background-color: #edf2f7;
    border-radius: 50px;
    width: 25px;
    height: 25px;

    background-color: ${(props) =>
    props.isActive ? `lightblue` : ''};
}
display:none;
`

export const TOCLink = styled.a`
margin-bottom: 1rem;
box-sizing: border-box;
min-width: 0px;
flex: 1 1 0%;
line-height: 1.33;
font-size: 1rem;
@media screen and (min-width: 768px){
    font-size: .85rem;
}
`

export const TableOfContentsWrapper = styled.div`
@media screen and (min-width: 1024px){
box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    background-color: #fff;
    border-radius: 1rem;
    transition: transform 250ms ease 0s, box-shadow 250ms ease 0s, color 250ms ease 0s;
    box-shadow: rgb(1 1 1 / 5%) 1px 1px 5px 0px;
    padding: 2rem;
}

@media screen and (max-width: 1024px){
    margin-left: 5%;
}
`

export const Bullet = styled.div`
    width: 8px;
    min-width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 2px solid
        ${(props) => (props.isActive ? `blue` : 'red')};
    background-color: ${(props) =>
        props.isActive ? `blue` : ''};
    margin: 8px;
    
`;

export const TOCFlexBox = styled.div`
display:flex;
align-items:center;
background: #FFF1DE;
background-color: #FFF1DE;
padding-bottom: 25px;
font-size: 1.5rem;

@media screen and (max-width: 1024px){
    justify-content: center;
}
@media screen and (max-width: 670px){
    padding-bottom: 0.5rem;
}
`



export const Item = styled.li`
@media screen and (max-width: 1024px){
list-style:disc;
}
    > div > a {
        text-decoration: none;

        color: ${(props) =>
            props.isActive ? 
            `black;
            -webkit-text-decoration: none;
            text-decoration: none;`
            : `grey`};
    }
    > div > a:hover {
        color: black;
    }
    > div {
        display: flex;
        min-height: 28px;
        line-height: 28px;

    }
`;