import {useLayoutEffect, useState} from "react";

const useCheckMobileScreen = () => {
    const [width, setWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : null);
    const handleWindowSizeChange = () => {
            setWidth(window.innerWidth);
    }

    useLayoutEffect(() => {
        if (typeof window !== "undefined") {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }
    }, []);

    return (width > 768);
}

export default useCheckMobileScreen