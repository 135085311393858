import { useEffect, useState } from "react";
export const useActiveId = (tableOfContents) => {
    const [activeHeading, setActiveHeading] = useState("");
    const filteredTableOfContents = tableOfContents.filter(function( obj ) {
        return obj.level === 0;
    });
    useEffect(() => {
        //   https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setActiveHeading(entry.target.id);
                }
            });
        }, { rootMargin: `0% 0% -40% 0%` });
        filteredTableOfContents.forEach((heading) => {
            // heading.url is string of hash that starts w/ '#'
            const headingElement = document.getElementById(heading.url.slice(1));
            // ! makes sure headingElement is never null
            if (headingElement == null){
                return;
            }
            observer.observe(headingElement);
        });
        // clean up
        return () => {
            filteredTableOfContents.forEach((heading) => {
                const headingElement = document.getElementById(heading.url.slice(1));
                if (headingElement == null){
                    return;
                }
                observer.unobserve(headingElement);
            });
            observer.disconnect();
        };
    }, [filteredTableOfContents]);
    return activeHeading;
};