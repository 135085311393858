import React from 'react';
import { getHeadings } from './getHeadings';
import { useActiveId } from "./useActive";
import { motion } from "framer-motion";
import { Container, Item, TableOfContentsWrapper,TableOfContentsTitle,TOCLink,CircledNumber,TOCFlexBox, ShowHideSpan  } from './styled';
import useCollapse from 'react-collapsed'
const tocWrapperVariant = {
    initial: { opacity: 0 },
    animate: {
        opacity: 1,
        transition: {
            delay: 0
        }
    }
};
const tocContainerVariant = {
    initial: {
        opacity: 0,
        y: 30
    },
    animate: (isFirstPass) => ({
        opacity: 1,
        y: 0,
        transition: {
            delay: isFirstPass * 0.1,
            when: "beforeChildren",
            staggerChildren: 0.2 // staggerChildren https://www.framer.com/docs/transition/###staggerchildren
        }
    })
};
const tocItemVariant = {
    initial: { y: 0, opacity: 0 },
    animate: {
        y: 0,
        opacity: 1
    }
};



const AnimatedLink = ({ href, isActive, title,number }) => {
    return (
    <div>
    <CircledNumber as={motion.div} variants={tocItemVariant} isActive={isActive}>{number + 1}</CircledNumber> 
    <TOCLink
      className="toc-link"
      href={href}
      style={{
        backgroundSize: isActive
          ? "var(--animated-link-bg-size)"
          : "var(--idle-link-bg-size)"
      }}
      onClick={(e) => {
        if(window !== "undefined"){
        e.preventDefault();
            const yCoordinate = document.querySelector(href).getBoundingClientRect().top + window.pageYOffset;         
            const yOffset = -105;           
            window.scrollTo({ top: yCoordinate + yOffset,behavior: 'smooth'});      

        }
    }}
    >
      {/* span is for animated text color animation */}
      <span
        style={{
          backgroundPosition: isActive
            ? "var(--animated-link-span-pos)"
            : "var(--idle-link-span-pos)"
        }}
      >
      {title}
      </span>
    </TOCLink>
    </div>
  );
}


const Toc = ({ tableOfContents}) => {

    const headingsWithLevels = getHeadings(tableOfContents);
    const activeHeading = useActiveId(headingsWithLevels);
    const config = {
        defaultExpanded: true
    };
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse(config);
return (
    <Container>
    <TOCFlexBox 
        {...getToggleProps()} >
        <TableOfContentsTitle>
        Table Of Contents
        </TableOfContentsTitle>
        <ShowHideSpan 
        as={motion.span}
        animate={{
        rotate: isExpanded? 180 : 0
        }}
        transition={{
            duration: .5
          }}
      >▼</ShowHideSpan>
    </TOCFlexBox>
    <TableOfContentsWrapper
        as={motion.div}
        className="ul-wrapper"
        initial="initial"
        animate="animate"
        variants={tocWrapperVariant}
        {...getCollapseProps()}
    >
    <motion.ul  custom={1} variants={tocContainerVariant}>
    {tableOfContents.items.map((heading, i) => (
    <Item  
        as={motion.li}
        variants={tocItemVariant}
        className="heading"
        key={heading.url}
        style={{
        paddingLeft: heading.level * 16,
        listStyle: 'none' // indent for sub headings
        }}
    >
        <AnimatedLink
        href={heading.url}
        isActive={activeHeading === heading.url.slice(1)}
        title = {heading.title}
        number = {i}
        >
        </AnimatedLink>
    </Item>
    ))}
    </motion.ul>
    </TableOfContentsWrapper>
    </Container>
    )
    }

export default Toc;